
.icon {

    fill: currentColor;
    height: 3rem;
    transition: $default-transition;
    width: 3rem;
}

/deep/ [class*="icon-"] {

    path {
        fill-opacity: 0;
        transition: $default-transition;
    }
    // transition: $def
    &.drawn {

        path {
            fill-opacity: 1;
        }
    }
}
